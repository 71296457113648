<template>
  <header id="header">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-md-4 align-self-center">
          <span
            id="menu-btn"
            @click="activeMenu = !activeMenu"
            :class="{ active: activeMenu }"
            class="svg-002-menu"
          ></span>
          <div id="navbar" :class="{ active: activeMenu }">
            <ul v-if="activeMenu" class="menu">
              <li class="active">
                <a href="https://ghayt.ma/boutique/">المتجــر الإلكتروني</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-md-3 align-self-center">
          <router-link to="/" class="logo">
            <img src="../assets/images/menuheader.webp" alt="Logo" />
          </router-link>
        </div>
        <div class="col-xl-4 col-md-5 align-self-center d-none d-md-block">
          <div class="social-wrapper">
            <a href="https://ghayt.ma/boutique/" class="btn-style transparent mr-5"
              >المتجــر الإلكتروني</a
            >
            <!-- <ul class="lang">
              <li class="active"><a href="#">FR</a></li>
              <li><a href="#">AR</a></li>
            </ul> -->
            <ul class="social-icons">
              <li>
                <a
                  href="https://www.instagram.com/ghayt.officiel/"
                  target="_blank"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://web.facebook.com/ghayt.officiel"
                  ><i class="fa-brands fa-facebook-f"></i
                ></a>
              </li>
              <!-- <li><a href="#"><i class="fa-solid fa-circle-play"></i></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "HeaderComponent",
  props: {},
  components: {},
  data: function () {
    return {
      activeMenu: false,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./src/assets/scss/standard.scss";

#header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  @media screen and (max-width: $screen-xs) {
    padding: 5px 0 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  &:before {
    content: "";
    background: $white;
    width: 100%;
    height: calc(50% + 35px);
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 15px 7px 23px rgb(187 229 232 / 30%);
    @media screen and (max-width: $screen-xs) {
      height: 50%;
    }
  }
  &:after {
    content: "";
    background: white;
    width: 450px;
    height: calc(100% - 25px);
    position: absolute;
    top: 20px;
    left: calc(50% - 5px);
    border-radius: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1199) {
      content: "";
      background: white;
      width: 239px;
      height: calc(100% - 25px);
      position: absolute;
      top: 0;
      left: calc(50% - 5px);
      border-radius: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: $screen-xs) {
      content: "";
      background: white;
      width: 239px;
      height: calc(100% - 25px);
      position: absolute;
      top: 0;
      left: calc(50% - 5px);
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }
  #navbar {
    @media screen and (max-width: $screen-xs) {
      position: absolute;
      top: 0;
      width: 100%;
      right: -100%;
      transition: all 1s ease;
      &.active {
        right: 0;
      }
    }
    ul.menu {
      margin: 0;
      @media screen and (max-width: $screen-xs) {
        position: absolute;
        top: 37px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 999999;
        background: $white;
        width: calc(100%);
        height: calc(100vh - 42px);
        padding: 80px 20px;
        text-align: left;
      }
      li {
        margin: 0 35px 0 0;
        position: relative;
        @media screen and (max-width: $screen-xs) {
          display: block;
          margin: 0;
          border-bottom: 1px solid #eeee;
          padding: 15px 0 15px;
          text-align: right;
          &:nth-of-type(5) {
            border-bottom: none;
          }
        }
        &:last-of-type {
          margin: 0;
        }
        &.active {
          &:before {
            content: "";
            background: $primary02;
            width: 100%;
            height: 4px;
            position: absolute;
            bottom: -15px;
            right: 0;
            @media screen and (max-width: $screen-xs) {
              background: $primary02;
              width: 5px;
              height: 100%;
              position: absolute;
              bottom: 0;
              right: -20px;
            }
          }
        }
      }
    }
  }
  a.logo {
    display: block;
    text-align: center;
    position: relative;
    z-index: 999999;
    img {
      max-width: 310px;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        max-width: 225px;
      }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        max-width: 160px;
      }
      @media screen and (max-width: $screen-xs) {
        width: 170px;
        z-index: 1111111;
      }
    }
  }
  ul.social-icons {
    li {
      display: inline-block;
      &:not(:last-of-type) {
        margin: 0 20px 0 0;
      }
      a {
        i {
          font-size: 15px;
          color: $primary01;
        }
      }
    }
  }
  .social-wrapper {
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
    z-index: 999999;
    ul.lang {
      margin: 0;
      li {
        margin: 0 9px 0 0;
        &:last-of-type {
          margin: 0;
        }
        &.active {
          a {
            color: $primary02;
          }
        }
      }
    }
  }
  &.inner-header {
    .col-xl-4 {
      .btn-style {
        position: relative;
        &:before {
          content: "";
          background: $primary02;
          width: calc(100% - 95px);
          height: 4px;
          position: absolute;
          bottom: -13px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .shopping-bag {
      @media screen and (max-width: $screen-xs) {
        position: absolute;
        right: 0;
        top: 5px;
      }
      .img-wrapper {
        img {
          cursor: pointer;
        }
      }
    }
  }
}

#menu-btn {
  display: none;
  @media screen and (max-width: $screen-xs) {
    display: block;
    position: absolute;
    top: 0;
    right: 22px;
    height: 25px;
    width: 35px;
    background: url("../assets/images/icons/hamburger.svg") no-repeat;
    background-size: 100%;
    z-index: 9999999;
    &.active {
      background: url("../assets/images/icons/002-cancel.svg") no-repeat;
      background-size: 100%;
      z-index: 99999999;
      position: fixed;
      top: 14px;
      right: 22px;
      height: 18px;
      width: 18px;
      margin: auto;
    }
  }
}
</style>
