<template>
  <div class="home">
    <Header />

    <section class="banner">
      <img
        src="../assets/images/onload-left-cloud.png"
        id="load-left-cloud"
        alt=""
      />
      <img
        src="../assets/images/onload-right-cloud.png"
        id="load-right-cloud"
        alt=""
      />

      <img
        src="../assets/images/banner-cloud-left.webp"
        alt=""
        class="banner-left-cloud"
        data-aos="fade-right"
        data-aos-delay="1600"
      />
      <img
        src="../assets/images/banner-cloud-right.webp"
        alt=""
        class="banner-right-cloud"
        data-aos="fade-left"
        data-aos-delay="1600"
      />
      <img
        src="../assets/images/banner-cloud-left.webp"
        alt=""
        class="hero-left-cloud"
      />
      <img
        src="../assets/images/banner-cloud-right.webp"
        alt=""
        class="hero-right-cloud"
      />
      <div class="banner-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-5 align-self-center">
              <div class="img-wrapper hero-image">
                <img
                  src="../assets/images/banner-medium-bottle.webp"
                  alt="water bottle"
                />
                <img
                  src="../assets/images/banner-extra-large-bottle.webp"
                  alt="water bottle"
                />
                <img
                  src="../assets/images/banner-large-bottle.webp"
                  alt="water bottle"
                />
                <img
                  src="../assets/images/banner-small-bottle.webp"
                  alt="water bottle"
                />
              </div>
            </div>
            <div style="z-index: 170" class="col-md-7 align-self-center">
              <div
                data-aos="fade-right"
                data-aos-delay="2700"
                class="content-wrapper"
              >
                <h2>
                  ماء مائدة<br />
                  <span>خفيف</span>
                </h2>
                <p>
                  غيث هو ماء مائدة سخي، مصفي بطريقة مثالية.
                  <br />
                  كيتميز بالتركيبة الأكثر خفة في السوق المغربية.
                </p>
                <div class="popup-button" v-b-modal.modal-1>
                  <div class="content">
                    <p>إقرأ المزيد</p>
                  </div>
                  <div class="plus">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-button">
            <a
              href="#"
              class="btn-style"
              style="z-index: 170"
              v-b-modal.modal-table
              >التركــــــــيبة</a
            >
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modal-1" ref="modal-1" centered title="">
      <div class="modal-body-wrapper">
        <span class="btn-close" @click="toggleModal('modal-1')"
          ><svg
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
        <div class="row">
          <div class="col-md-8 align-self-center">
            <div class="content-wrapper">
              <h2>
                خفيف<br />
                على <span>القلب </span>
              </h2>
              <p>
                غيث هو ماء مائدة سخي، مصفي بطريقة مثالية. كيتميز بأخف تركيبة
                معدنية في السوق المغربية، و كيحتوي على نسبة قليلة من الصوديوم،
                الشيء اللي كيعطيه مذاق خفيف لاستهلاك يومي للجميع.
              </p>
            </div>
          </div>
          <div class="col-md-4 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/popup-large-bottle.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-table" ref="modal-table" centered title="">
      <div class="modal-body-wrapper modal-table">
        <span class="btn-close" @click="toggleModal('modal-table')"
          ><svg
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
        <div class="row">
          <div class="col-md-4 align-self-center">
            <div class="img-wrapper">
              <img
                src="../assets/images/popup-three-table-bottles.webp"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-8 align-self-center recipie">
            <div class="content-wrapper">
              <h3>تركيبة الماء مغ / لتر</h3>
              <div class="popup-table-wrapper">
                <div class="table-wrapper">
                  <div class="item-price">
                    <strong>8</strong>
                  </div>
                  <div class="item-name">
                    <strong>كلسيوم</strong>
                  </div>
                </div>

                <div class="table-wrapper">
                  <div class="item-price">
                    <strong>2</strong>
                  </div>
                  <div class="item-name">
                    <strong>مغنيزيوم</strong>
                  </div>
                </div>

                <div class="table-wrapper">
                  <div class="item-price">
                    <strong>&lt;2</strong>
                  </div>
                  <div class="item-name">
                    <strong>نترات</strong>
                  </div>
                </div>

                <div class="table-wrapper active">
                  <div class="item-price">
                    <strong>&lt;10</strong>
                  </div>
                  <div class="item-name">
                    <strong>صوديوم</strong>
                  </div>
                </div>

                <div class="table-wrapper active">
                  <div class="item-price">
                    <strong>100</strong>
                  </div>
                  <div class="item-name">
                    <strong>الأملاح الكلية الذائبة</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <section class="pure-light-water">
      <div class="pure-light-water-wrapper">
        <img
          src="../assets/images/pure-clean-water-left-cloud.webp"
          alt=""
          data-aos="fade-right"
          data-aos-delay="900"
          class="pure-light-water-wrapper-before"
        />
        <img
          src="../assets/images/pure-clean-water-right-cloud.webp"
          alt=""
          data-aos="fade-left"
          data-aos-delay="1200"
          class="pure-light-water-wrapper-after"
        />
        <div class="container">
          <div class="pure-girl-wrapper">
            <img
              src="../assets/images/pure-clean-water-u-bottom-cloud1.webp"
              alt=""
              data-aos="fade-up-girl"
              class="pure-light-girl-cloud"
            />
            <img
              src="../assets/images/pure-clean-water-u-bottom-cloud2.webp"
              alt=""
              data-aos="fade-up-girl"
              class="pure-light-girl-cloud active"
            />
          </div>

          <div class="row">
            <div class="col-xl-4 col-md-6 align-self-center">
              <div data-aos="fade-right" class="content-wrapper">
                <h2>
                  خفيف<br />
                  على <span>القلب</span>
                </h2>
                <p>
                  غيث ماء المائدة الخفيف على القلب و الي كيرافقك طيلة اليوم بفضل
                  تركيبتو الخفيفة المتميزة، أحجامو السخية و بأفضل الأثمنة.
                </p>
                <div class="popup-button" v-b-modal.modal-2>
                  <div class="content">
                    <p>إقرأ المزيد</p>
                  </div>
                  <div class="plus">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-6 d-md-none d-lg-none d-xl-block align-self-center"
            >
              <div class="ballon-animation">
                <img
                  src="../assets/images/pure-water-small-ballons.webp"
                  alt=""
                />
                <img src="../assets/images/pure-water-big-ballon.webp" alt="" />
              </div>
            </div>
            <div class="col-xl-4 col-md-6 align-self-center">
              <div class="banner-img">
                <img
                  src="../assets/images/pure-light-water-banner-imga.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modal-2" ref="modal-2" centered title="">
      <div class="modal-body-wrapper">
        <span class="btn-close" @click="toggleModal('modal-2')"
          ><svg
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
        <div class="row">
          <div class="col-md-8 align-self-center">
            <div class="content-wrapper">
              <h2>
                خفيف<br />
                على <span>القلب </span>
              </h2>
              <p>
                غيث ماء المائدة الخفيف الي كيرافقك طيلة اليوم، غيث خفيف على
                القلب بفضل تركيبتو الخفيفة المتميزة، أحجامو السخية <br />
                و بأفضل الأثمنة.
              </p>
            </div>
          </div>
          <div class="col-md-4 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/popup-large-bottle.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <section class="water-purification">
      <img
        src="../assets/images/water-purification-left-cloud.png"
        alt=""
        class="water-purification-left-cloud"
      />
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-md-6 d-none d-md-none d-lg-none d-xl-block align-self-center"
          >
            <div class="banner-img">
              <img
                src="../assets/images/water-purification-banner-imga.webp"
                alt=""
              />
            </div>
          </div>
          <div class="col-xl-4 col-md-6 align-self-center">
            <div class="img-wrapper water-purification-bottles">
              <div
                data-aos="fade-up-bottle"
                data-aos-delay="600"
                class="bottle-1"
              >
                <img
                  src="../assets/images/banner-medium-bottle.webp"
                  alt="water bottle"
                />
              </div>
              <div data-aos="fade-up-bottle" class="bottle-2">
                <img
                  src="../assets/images/banner-extra-large-bottle.webp"
                  alt="water bottle"
                />
              </div>
              <div
                data-aos="fade-up-bottle"
                data-aos-delay="1000"
                class="bottle-3"
              >
                <img
                  src="../assets/images/banner-large-bottle.webp"
                  alt="water bottle"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 align-self-center">
            <div data-aos="fade-left" class="content-wrapper">
              <h2>
                خفيف<br />
                على <span>الجيب</span>
              </h2>

              <p>غيث كيقدم أحجام فريدة، سخية، وبثمن مناسب.</p>
              <div class="popup-button" v-b-modal.modal-3>
                <div class="content">
                  <p>إقرأ المزيد</p>
                </div>
                <div class="plus">
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../assets/images/water-purification-right-cloud.webp"
          alt=""
          class="water-purification-right-cloud"
          data-aos="fade-left"
          data-aos-delay="1000"
        />

        <img
          src="../assets/images/water-purification-bottom-cloud.webp"
          alt=""
          class="water-purification-bottom-cloud"
        />
      </div>
    </section>

    <b-modal id="modal-3" ref="modal-3" class="" centered title="">
      <div class="modal-body-wrapper large-popup">
        <span class="btn-close" @click="toggleModal('modal-3')"
          ><svg
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
        <div class="row">
          <div class="col-md-8 align-self-center">
            <div class="content-wrapper">
              <h2>
                خفيف<br />
                على <span>الجيب </span>
              </h2>
              <p>
                غيث كيقدم أحجام فريدة، سخية، وبثمن مناسب : 6 لتر غير بـ 13 درهم،
                2 لتر غير بـ5.5 دراهم، فحين 0.6 لتر غير بـ3 دراهم.
              </p>
            </div>
          </div>
          <div class="col-md-4 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/popup-three-bottles.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <section class="pure-light-water pure-light-water-1">
      <div class="pure-light-water-wrapper">
        <img
          src="../assets/images/pure-clean-water-left-cloud.webp"
          alt=""
          data-aos="fade-right"
          data-aos-delay="900"
          class="pure-light-water-wrapper-before"
        />
        <img
          src="../assets/images/pure-clean-water-right-cloud.webp"
          alt=""
          data-aos="fade-left"
          data-aos-delay="1200"
          class="pure-light-water-wrapper-after"
        />
        <div class="container">
          <div class="pure-girl-wrapper">
            <img
              src="../assets/images/pure-clean-water-u-bottom-cloud1.webp"
              alt=""
              data-aos="fade-up-girl"
              class="pure-light-girl-cloud"
            />
            <img
              src="../assets/images/pure-clean-water-u-bottom-cloud2.webp"
              alt=""
              data-aos="fade-up-girl"
              class="pure-light-girl-cloud active"
            />
          </div>

          <div class="row">
            <div class="col-xl-4 col-md-6 align-self-center">
              <div data-aos="fade-right" class="content-wrapper">
                <h2>
                  خفيف<br />
                  على <span>الصحة</span>
                </h2>
                <p>
                  ماء غيث صديق للصحة بفضل تركيبتو الأكثر خفة في السوق المغربية
                  ونسبة الصوديوم القليلة لي كيحتوي عليها.
                </p>
                <div class="popup-button" v-b-modal.modal-4>
                  <div class="content">
                    <p>إقرأ المزيد</p>
                  </div>
                  <div class="plus">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-6 align-self-center d-md-none d-lg-none d-xl-block"
            >
              <div class="ballon-animation">
                <img
                  src="../assets/images/pure-water-small-ballons.webp"
                  alt=""
                />
                <!--                <img src="../assets/images/pure-water-big-ballon.webp" alt="">-->
              </div>
            </div>
            <div class="col-xl-4 col-md-6 align-self-center">
              <div class="banner-img">
                <img
                  src="../assets/images/pure-light-water-1-banner-imga.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modal-4" ref="modal-4" centered title="">
      <div class="modal-body-wrapper">
        <span class="btn-close" @click="toggleModal('modal-4')"
          ><svg
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
        <div class="row">
          <div class="col-md-8 align-self-center">
            <div class="content-wrapper">
              <h2>
                خفيف<br />
                على <span>الصحة </span>
              </h2>
              <p>
                ماء غيث صديق للصحة بفضل تركيبتو الأكثر خفة في السوق المغربية
                ونسبة الصوديوم القليلة لي كيحتوي عليها. غيث كايساعدنا نشربو
                القدر الكافي من الماء اللي كيحتاج الجسم ديالنا يوميا.
              </p>
            </div>
          </div>
          <div class="col-md-4 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/popup-medium-bottle.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <section class="pure-light-water pure-light-water-2">
      <div class="pure-light-water-wrapper">
        <img
          src="../assets/images/pure-clean-water-left-cloud.webp"
          alt=""
          data-aos="fade-right"
          data-aos-delay="900"
          class="pure-light-water-wrapper-before"
        />
        <img
          src="../assets/images/pure-clean-water-right-cloud.webp"
          alt=""
          data-aos="fade-left"
          data-aos-delay="1200"
          class="pure-light-water-wrapper-after"
        />
        <img
          src="../assets/images/water-purification-bottom-cloud.webp"
          alt=""
          class="water-purification-bottom-cloud"
        />
        <div class="container">
          <div class="pure-girl-wrapper">
            <img
              src="../assets/images/pure-clean-water-u-bottom-cloud1.webp"
              alt=""
              data-aos="fade-up-girl"
              class="pure-light-girl-cloud"
            />
            <img
              src="../assets/images/pure-clean-water-u-bottom-cloud2.webp"
              alt=""
              data-aos="fade-up-girl"
              class="pure-light-girl-cloud active"
            />
          </div>

          <div class="row">
            <div class="col-xl-6 col-md-6 align-self-center">
              <div class="banner-img">
                <img
                  src="../assets/images/pure-light-water-2-banner-imga.webp"
                  alt="family"
                  class="family-image"
                />
              </div>
            </div>
            <div
              class="col-xl-1 col-md-6 align-self-center d-md-none d-lg-none d-xl-block"
            ></div>
            <div class="col-xl-5 col-md-6 align-self-center">
              <div data-aos="fade-right" class="content-wrapper">
                <h2>
                  خفيف<br />
                  على <span>الحباب</span>
                </h2>
                <p>
                  غيث منتوج مغربي، كله منفعة لينا؛ لوليداتنا ولواليدينا ولجميع
                  الأحباب والاصدقاء.
                </p>
                <div class="popup-button" v-b-modal.modal-5>
                  <div class="content">
                    <p>إقرأ المزيد</p>
                  </div>
                  <div class="plus">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modal-5" ref="modal-5" centered title="">
      <div class="modal-body-wrapper">
        <span class="btn-close" @click="toggleModal('modal-5')"
          ><svg
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
        <div class="row">
          <div class="col-md-8 align-self-center">
            <div class="content-wrapper">
              <h2>
                خفيف<br />
                على <span>الحباب </span>
              </h2>
              <p>
                غيث منتوج مغربي، كله منفعة لينا؛ لوليداتنا ولواليدينا ولجميع
                الأحباب والاصدقاء. كل شخص، كبير أو صغير، يقدر يلقا في غيث الماء
                الي كيناسب الاختيار ديالو.
              </p>
            </div>
          </div>
          <div class="col-md-4 align-self-center">
            <div class="img-wrapper">
              <img
                src="../assets/images/popup-extra-large-bottle.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <Footer />
    <!-- <span v-html="rain"></span> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },

  methods: {
    rainStart() {
      var increment = 0;
      var drops = "";

      while (increment < 95) {
        var randoHundo = Math.floor(Math.random() * (98 - 1 + 1) + 1);
        var randoFiver = Math.floor(Math.random() * (4 - 2 + 1) + 2);
        increment += randoFiver;
        drops +=
          '<div class="drop" style="left: ' +
          increment +
          "%; bottom: " +
          (randoFiver + randoFiver - 1 + 100) +
          "%; animation-delay: 0." +
          randoHundo +
          "s; animation-duration: 0.5" +
          randoHundo +
          's;"><div class="stem" style="animation-delay: 0.' +
          randoHundo +
          "s; animation-duration: 0.5" +
          randoHundo +
          's;"></div><div class="splat" style="animation-delay: 0.' +
          randoHundo +
          "s; animation-duration: 0.5" +
          randoHundo +
          's;"></div></div>';
      }
      this.rain = drops;
    },
    toggleModal(ref) {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs[ref].toggle("#toggle-btn");
    },
  },
  mounted() {
    this.rainStart();
    window.setInterval(() => {
      document.getElementById("load-left-cloud").classList.add("active");
      document.getElementById("load-right-cloud").classList.add("active");
    }, 800);
  },
};
</script>

<style lang="scss" scoped>
p {
  direction: rtl;
}
.hero-right-cloud {
  position: absolute;
  top: 47%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 150;
  opacity: 0.9;
}
.hero-left-cloud {
  position: absolute;
  top: 47%;
  right: 0%;
  width: 100%;
  height: 100%;
  z-index: 150;
  opacity: 0.9;
}
.btn-close {
  position: absolute;
  top: 60px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 99999px;
  background-color: #0e7c9e;
  &:hover {
    background-color: #1181a3;
  }
  @media screen and (max-width: 991px) {
    top: 10px;
    right: 10px;
    display: none;
  }
  svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
  }
}
@media screen and (max-width: 767px) {
  #modal-table {
    .img-wrapper {
      position: absolute;
      top: -25px;
      left: 45px;
      img {
        max-height: 150px;
      }
    }
    .content-wrapper .popup-table-wrapper {
      max-height: 50vh;
      overflow-y: scroll;
    }
  }
  #modal-3 {
    .img-wrapper {
      position: absolute;
      top: -25px;
      left: 45px;
      img {
        max-height: 150px;
      }
    }
    .content-wrapper p {
      max-height: 50vh;
      overflow-y: scroll;
    }
  }
}
.hero-image img {
  bottom: 0 !important;
}
.family-image {
  max-width: 750px !important;
}
@media screen and (max-width: 767px) {
  .family-image {
    width: 90% !important;
  }
}
</style>
