<template>
  <footer id="footer">
    <!--    <img src="../assets/images/footer-water-wave.webp" class="footer-water-wave" alt="">-->
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-md-2 col-sm-12 col-12">
          <div class="social-detail align-self-center">
            <!-- <ul class="language">
              <li><p>FR</p></li>
              <li><p>AR</p></li>
            </ul> -->
            <p class="temp">تابعونا على</p>
            <ul class="social-icon">
              <li>
                <a
                  href="https://www.instagram.com/ghayt.officiel/?igshid=NDk5N2NlZjQ%3D"
                  target="_blank"
                  ><img src="../assets/images/icons/insta-footer.svg" alt=""
                /></a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ghayt.officiel"
                  target="_blank"
                  ><img src="../assets/images/icons/facebook-footer.svg" alt=""
                /></a>
              </li>
              <!-- <li>
                <a href="#"
                  ><img src="../assets/images/icons/play-footer.svg" alt=""
                /></a>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12 col-12 align-self-center">
          <div class="footer-content">
            <p class="sub-heading">تواصلوا معنا</p>
            <span> كلم 15 الطريق الرابط بين تازة وفاس - المغرب, Sotherma </span>
            <ul>
              <li>
                <a href="mailto:Contact@sotherma.ma"
                  >Contact@sotherma.ma <i class="fa-solid fa-envelope"></i
                ></a>
              </li>
              <li>
                <a href="tel:06 08 051 051"
                  >06 08 051 051 <i class="fa-solid fa-phone"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-xl-4 col-md-4 col-sm-12 main-footer-logo col-12 align-self-center"
        >
          <div class="footer-logo">
            <img src="../assets/images/logo-arabic.svg" alt="" />
          </div>
          <span class="copyright"
            >حقوق النشر 2023 . غيث ©جميع الحقوق محفوظة</span
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterComponent",
  props: {},
  components: {},
  data: function () {
    return {
      activeMenu: false,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./src/assets/scss/standard.scss";
#footer {
  position: relative;
  padding: 195px 0 111px;
  margin-top: 200px;
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    padding: 195px 0 35px;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding: 195px 0 35px;
  }
  @media screen and (max-width: 1199px) {
    padding: 150px 0 40px;
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    .col-xl-4.col-md-4.col-sm-12.main-footer-logo.col-12.align-self-center {
      display: none;
    }
  }
  &:before {
    content: "";
    background-image: url("../assets/images/footer.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  // .col-xl-2 {
  //   @media screen and (max-width: 1199px) {
  //     order: 2;
  //   }
  // }
  // .col-xl-6 {
  //   @media screen and (max-width: 1199px) {
  //     display: none;
  //   }
  // }
  .col-xl-4 {
    @media screen and (max-width: 1199px) {
      order: 1;
    }
  }

  p {
    color: white;
  }
  .footer-logo {
    text-align: center;
    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }
  span.copyright {
    color: white;
    display: block;
    margin-top: 40px;
    text-align: center;
    @media screen and (max-width: 1199px) {
      margin-top: 20px;
      text-align: center;
    }
  }
  .footer-content {
    text-align: right;
    p.sub-heading {
      margin: 30px 0 40px;
    }
    span {
      color: white;
    }
    ul {
      padding-left: 0;
      margin: 15px 0 0;
      li {
        display: block;
        margin-bottom: 15px;
        a {
          color: $white;
          font-size: 20px;
          i {
            margin-left: 10px;
            color: $white;
            font-size: 20px;
          }
          &:hover {
            i {
              color: #fcd200;
            }
          }
        }
      }
    }
  }
  .temp {
    margin-top: 60px;
  }
  .social-detail {
    text-align: right;

    @media screen and (max-width: 1199px) {
      text-align: center;
      .temp {
        margin-top: 80px;
      }
    }
    ul.language {
      margin: 0 0 30px;
      padding: 0;
      @media screen and (max-width: 1199px) {
        margin: 30px 0 10px;
      }
      li {
        display: inline-block;
        margin: 0 20px 0 0;
        p {
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
    ul.social-icon {
      margin: 30px 0 0;
      padding: 0;
      @media screen and (max-width: 1199px) {
        text-align: center;
      }
      li {
        display: inline-block;
        &:not(:last-of-type) {
          margin: 0 20px 0 0;
        }
        a {
          width: 40px;
          height: 40px;
          border: 3px solid white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
