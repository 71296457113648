<template> 
  <div id="app" :class="this.$route.name">
    <router-view />
  </div> 
</template>
<script>
// @ is an alias to /src

export default {
  name: "AppView",
  components: {},
  data: function () {
    return {
      rain: "",
    };
  },
  
};
</script>

<style>
/* .home { */
/*   height: 100vh; */
/*   overflow-y: scroll; */
/*   scroll-snap-type: y mandatory; */
/* } */
/* .home section { */
/*   scroll-snap-align: start; */
/*   height: 100vh; */
/* } */
/* .home footer { */
/*   scroll-snap-align: start; */
/* } */
</style>
